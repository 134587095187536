import React from 'react';
import LottieAnimation from 'components/atoms/LottieAnimation';

interface EngageAutomationSectionProps {
  title: string;
  body: string;
}

const EngageAutomationSection: React.FC<EngageAutomationSectionProps> = ({
  title,
  body,
}) => {
  return (
    <div className="my-20 md:my-32 text-center">
      <h2 className="u-h2 pt-8 mb-8 md:mb-12 px-6 lg:px-md">{title}</h2>

      <div className="mx-auto px-6 max-w-5xl">
        <div className="shadow-2xl" style={{ borderRadius: '2rem' }}>
          <LottieAnimation
            lazyData="dashboard"
            play={true}
            className="max-w-screen"
          />
        </div>
      </div>

      <p className="mt-6 md:mt-12 mx-auto text-lg w-220 max-w-full px-6 lg:px-md">
        {body}
      </p>
    </div>
  );
};

export default EngageAutomationSection;
