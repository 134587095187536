import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import CtaSection from 'components/organisms/CtaSection';
import EngageAutomationSection from 'components/organisms/EngageAutomationSection';
import EngageBenefitsSection from 'components/organisms/EngageBenefitsSection';
import Hero from 'components/organisms/Hero';
import FeaturesSection from 'components/organisms/FeaturesSection';
import SupportSection from 'components/organisms/SupportSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import { graphql } from 'gatsby';
import getImageData from 'helpers/nf-image-data';

const heroImageData = getImageData('/images/fluid/engage-hero.jpg');

const RetailEngageContent = ({
  hero,
  benefit,
  testimonial,
  benefitsWithDivider,
  automation,
  benefit2,
  features,
  support,
  testimonial2,
  cta,
}) => {
  return (
    <>
      <Hero
        breadcrumb="Engage"
        heading={hero?.title}
        content={hero?.description}
        imageData={heroImageData}
        reverse={true}
        mask={true}
        button={{
          to: hero?.primaryCta?.url,
          label: hero?.primaryCta?.label,
        }}
        cards={[
          {
            heading: 'Buy 1 Get 1 Free',
            content: 'Brand Funded',
            color: 'orange',
            y: '51%',
            x: '59%',
            mobileY: '53%',
            mobileX: '40%',
          },
          {
            heading: 'Buy 2 Get $1 off',
            content: 'Brand Funded',
            color: 'aqua',
            y: '36%',
            x: '99%',
            mobileY: '30%',
            mobileX: '65%',
          },
        ]}
      />

      <div className="container max-w-screen-xl mx-auto my-20 md:my-32">
        <Benefit
          title={benefit?.title}
          body={benefit?.body}
          imageSide={benefit?.imageSide}
          image={benefit?.image}
        />
      </div>

      <TestimonialSection
        quote={testimonial?.quote}
        author={testimonial?.author}
        company={testimonial?.company}
        inverted
        className="bg-aqua mt-0 md:mt-0"
      />

      <EngageBenefitsSection benefits={benefitsWithDivider} />

      <EngageAutomationSection
        title={automation?.title}
        body={automation?.body}
      />

      <div className="container max-w-screen-xl mx-auto my-20 md:my-32">
        <Benefit
          title={benefit2?.title}
          body={benefit2?.body}
          imageSide={benefit2?.imageSide}
          image={benefit2?.image}
        />
      </div>

      <FeaturesSection title={features?.title} body={features.body}>
        {features?.features?.map(({ title, body, image }, index) => (
          <Feature key={index} title={title} body={body} image={image} />
        ))}
      </FeaturesSection>

      <SupportSection
        title={support?.title}
        body={support?.body}
        imageUrl={support?.image}
      />

      <TestimonialSection
        quote={testimonial2?.quote}
        ctaLabel="Read customer story"
      />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const RetailEngagePreview = ({ entry }) => {
  return <RetailEngageContent {...entry.get('data').toJS()} />;
};

const RetailEngage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <RetailEngageContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query RetailEngageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/convenience-retail/engage/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        benefit {
          title
          body
          image
          imageSide
        }
        testimonial {
          quote
          author
          company
        }
        benefitsWithDivider {
          title
          body
          image
        }
        automation {
          title
          body
        }
        benefit2 {
          title
          body
          image
          imageSide
        }
        features {
          title
          body
          features {
            title
            body
            image
          }
        }
        support {
          title
          body
          image
        }
        testimonial2 {
          quote
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default RetailEngage;
