import React from 'react';
import Benefit from 'components/molecules/Benefit';

interface EngageBenefitsSectionProps {
  benefits: {
    title: string;
    body: string;
    image: string;
  }[];
}

const EngageBenefitsSection: React.FC<EngageBenefitsSectionProps> = ({
  benefits,
}) => {
  return (
    <div className="container max-w-screen-xl mx-auto my-20 md:my-32">
      <Benefit
        title={benefits[0].title}
        body={benefits[0].body}
        image={benefits[0].image}
        imageSide="right"
        margin={false}
      >
        <p className="font-semibold mt-8 lg:mt-16 mb-4">Brands including</p>
        <img
          src="/images/engage-logos.svg"
          alt="Logos"
          className="h-8 w-auto"
        />
      </Benefit>

      <div className="flex items-center py-10 lg:py-20">
        <div className="flex-grow border-b border-black border-dashed border-opacity-10" />
        <div className="px-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fill="#00B481"
              d="M15.862 6.446H9.934V.176H6.742v6.27H.814V9.22h5.928v6.27h3.192V9.22h5.928V6.446z"
            ></path>
          </svg>
        </div>
        <div className="flex-grow border-b border-black border-dashed border-opacity-10" />
      </div>

      <Benefit
        title={benefits[1].title}
        body={benefits[1].body}
        image={benefits[1].image}
        imageSide="left"
        margin={false}
      />
    </div>
  );
};

export default EngageBenefitsSection;
